<template>
	<div>
		<van-nav-bar
			title="香港門店及地址地圖"
			left-arrow
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<div id="map" class="wid-100">
			
		</div>
		
	</div>
</template>

<script>
	import Vue from "vue"
	import { NavBar } from "vant"
	Vue.use(NavBar);
	
	export default{
		name: 'addressMap',
		data (){
			return {
				area: JSON.parse(sessionStorage.getItem('address')) || this.$route.params,//当前搜索地址
				map: null//地图
			}
		},
		mounted (){
			//获取设置地图容器高度
			this.getHeight(sessionStorage.getItem('address'));
			window.onresize = this.getHeight;
			
			//加载地图
			this.$nextTick(()=>{
				this.loadBMap();
			})
		},
		methods: {
			getHeight (){
				var height = window.innerHeight;//视口高度
				var navbarHeight = window.getComputedStyle(document.querySelector('.qjc-nav-bar')).height.replace('px','');
				// console.log(height,navbarHeight);
				document.getElementById('map').style.height = (height-navbarHeight) + 'px';
			},
			
			loadBMap() {//加载地图插件
				var that = this;
			  return new Promise(function(resolve, reject) {
			    window.onBMapCallback = function() {
			        resolve(BMap);
					that.map = new BMap.Map('map'); // 创建Map实例
					
					var geolocation = new BMap.Geolocation();
					var point;
					geolocation.getCurrentPosition(function(r){
						if(this.getStatus() == BMAP_STATUS_SUCCESS){
							var mk = new BMap.Marker(r.point);
							that.map.addOverlay(mk);
							//初始位置(定位当前所在位置)
							point = new BMap.Point(r.point.lng, r.point.lat);
							that.map.centerAndZoom(point, 15);
							
							//搜索实例化
							var local = new BMap.LocalSearch('香港', {      
							    renderOptions:{map: that.map},
								pageCapacity: 1//只显示一个结果
							});  
							
							//搜索地点
							local.search(that.area.address);
							
							//更改标注信息
							local.setMarkersSetCallback(function (pois){
								pois[0].title = that.area.name;//标题
								pois[0].address = that.area.address;//地址
								pois[0].phoneNumber = that.area.tel;//电话
								pois[0].detailUrl = '';//去除详情跳转(否则搜出来的名称等和标注卡不一样)
								pois[0].marker.setTitle(that.area.name);//标注鼠标移入title
							});
						}
						else {
							alertErrBox('failed'+this.getStatus());
						}        
					});
			    }
				
			    let script = document.createElement('script')
			    script.type = 'text/javascript';
			    script.src = 'https://api.map.baidu.com/api?v=2.0&ak=9GuTagy1ldILfl1yrZDzAl5EiVT83Vcu&s=1&callback=onBMapCallback';
			    script.onerror = reject;
			    document.body.appendChild(script);
			  })
			}
		},
		destroyed (){
			sessionStorage.removeItem('address');
		}
	}
</script>

<style>
	
</style>
